const _0 = "0rem"
const _none = "none"
const _xs = "20rem"
const _sm = "24rem"
const _md = "28rem"
const _lg = "32rem"
const _xl = "36rem"
const _2xl = "42rem"
const _3xl = "48rem"
const _4xl = "56rem"
const _5xl = "64rem"
const _6xl = "72rem"
const _7xl = "80rem"
const _full = "100%"
const _min = "min-content"
const _max = "max-content"
const _fit = "fit-content"
const _prose = "65ch"
const config = { "0": _0, "none": _none, "xs": _xs, "sm": _sm, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "7xl": _7xl, "full": _full, "min": _min, "max": _max, "fit": _fit, "prose": _prose, "screen-sm": "640px", "screen-md": "768px", "screen-lg": "1024px", "screen-xl": "1280px", "screen-2xl": "1536px", "screen-hd": "1920px",  }
export { config as default, _0, _none, _xs, _sm, _md, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl, _7xl, _full, _min, _max, _fit, _prose }