const _xs = ["0.75rem",{"lineHeight":"1.3333333333333333/* 16px */"}]
const _sm = ["0.875rem",{"lineHeight":"1.4285714285714286/* 20px */"}]
const _base = ["1rem",{"lineHeight":"1.5/* 24px */"}]
const _lg = ["1.125rem",{"lineHeight":"1.5555555555555556/* 28px */"}]
const _xl = ["1.25rem",{"lineHeight":"1.4/* 28px */"}]
const _2xl = ["1.5rem",{"lineHeight":"1.6666666666666667/* 40px */","letterSpacing":"-0.01em"}]
const _3xl = ["2rem",{"lineHeight":"1.25/* 40px */","letterSpacing":"-0.01em"}]
const _4xl = ["2.25rem",{"lineHeight":"1.3333333333333333/* 48px */","letterSpacing":"-0.01em"}]
const _5xl = ["3rem",{"lineHeight":"1.1666666666666667/* 56px */","letterSpacing":"-0.03em"}]
const _6xl = ["4rem",{"lineHeight":"1.25/* 80px */","letterSpacing":"-0.03em"}]
const _7xl = ["5rem",{"lineHeight":"1.1/* 88px */","letterSpacing":"-0.03em"}]
const _8xl = ["6rem",{"lineHeight":"1.1666666666666667/* 112px */","letterSpacing":"-0.01em"}]
const config = { "xs": _xs, "sm": _sm, "base": _base, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "7xl": _7xl, "8xl": _8xl, "xs-ub": ["0.75rem",{"lineHeight":"1.3333333333333333/* 16px */","fontWeight":900}], "sm-ub": ["0.875rem",{"lineHeight":"1.4285714285714286/* 20px */","fontWeight":900}], "base-ub": ["1rem",{"lineHeight":"1.5/* 24px */","fontWeight":900}], "lg-ub": ["1.125rem",{"lineHeight":"1.5555555555555556/* 28px */","fontWeight":900}], "xl-ub": ["1.25rem",{"lineHeight":"1.4/* 28px */","fontWeight":900}], "2xl-ub": ["1.5rem",{"lineHeight":"1.6666666666666667/* 40px */","letterSpacing":"-0.01em","fontWeight":900}], "3xl-ub": ["2rem",{"lineHeight":"1.25/* 40px */","letterSpacing":"-0.01em","fontWeight":900}], "4xl-ub": ["2.25rem",{"lineHeight":"1.3333333333333333/* 48px */","letterSpacing":"-0.01em","fontWeight":900}], "5xl-ub": ["3rem",{"lineHeight":"1.1666666666666667/* 56px */","letterSpacing":"-0.03em","fontWeight":900}], "6xl-ub": ["4rem",{"lineHeight":"1.25/* 80px */","letterSpacing":"-0.03em","fontWeight":900}], "7xl-ub": ["5rem",{"lineHeight":"1.1/* 88px */","letterSpacing":"-0.03em","fontWeight":900}], "8xl-ub": ["6rem",{"lineHeight":"1.1666666666666667/* 112px */","letterSpacing":"-0.01em","fontWeight":900}],  }
export { config as default, _xs, _sm, _base, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl, _7xl, _8xl }