const _0 = "0s"
const _75 = "75ms"
const _100 = "100ms"
const _150 = "150ms"
const _200 = "200ms"
const _300 = "300ms"
const _400 = "400ms"
const _500 = "500ms"
const _600 = "600ms"
const _700 = "700ms"
const _800 = "800ms"
const _1000 = "1000ms"
const config = { "0": _0, "75": _75, "100": _100, "150": _150, "200": _200, "300": _300, "400": _400, "500": _500, "600": _600, "700": _700, "800": _800, "1000": _1000,  }
export { config as default, _0, _75, _100, _150, _200, _300, _400, _500, _600, _700, _800, _1000 }