const _transparent = "transparent"
const _current = "currentColor"
const _dark = "#1A1A1A"
const _gray = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const _light = "#F5F5F5"
const _green = "#01dc83"
const _disabled = "#E5E5DF"
const _primary = "#7810BF"
const _secondary = "#1A1A1A"
const _black = "#000000"
const _white = "#ffffff"
const _red = {"500":"#FF0000"}
const _neutral = {"100":"#EEEEEE","200":"#DCDCDC","300":"#CCCCCC","800":"#212121","900":"#111111"}
const _teal = {"50":"#D9E9E9","300":"#0F9C9C","500":"#0A8080"}
const _purple = {"500":"#661D88"}
const _blue = {"500":"#315B80"}
const _orange = {"500":"#D65A1A"}
const _beige = "#E5E2CD"
const config = { "transparent": _transparent, "current": _current, "dark": _dark, "gray": _gray, "light": _light, "green": _green, "disabled": _disabled, "primary": _primary, "secondary": _secondary, "black": _black, "white": _white, "red": _red, "neutral": _neutral, "teal": _teal, "purple": _purple, "blue": _blue, "orange": _orange, "beige": _beige,  }
export { config as default, _transparent, _current, _dark, _gray, _light, _green, _disabled, _primary, _secondary, _black, _white, _red, _neutral, _teal, _purple, _blue, _orange, _beige }