const _0 = "0"
const _5 = "0.05"
const _10 = "0.1"
const _20 = "0.2"
const _25 = "0.25"
const _30 = "0.3"
const _40 = "0.4"
const _50 = "0.5"
const _60 = "0.6"
const _70 = "0.7"
const _75 = "0.75"
const _80 = "0.8"
const _90 = "0.9"
const _95 = "0.95"
const _100 = "1"
const config = { "0": _0, "5": _5, "10": _10, "20": _20, "25": _25, "30": _30, "40": _40, "50": _50, "60": _60, "70": _70, "75": _75, "80": _80, "90": _90, "95": _95, "100": _100,  }
export { config as default, _0, _5, _10, _20, _25, _30, _40, _50, _60, _70, _75, _80, _90, _95, _100 }