const _0 = "0"
const _1 = "1"
const _2 = "2"
const _10 = "10"
const _20 = "20"
const _30 = "30"
const _40 = "40"
const _50 = "50"
const _auto = "auto"
const config = { "0": _0, "1": _1, "2": _2, "10": _10, "20": _20, "30": _30, "40": _40, "50": _50, "auto": _auto,  }
export { config as default, _0, _1, _2, _10, _20, _30, _40, _50, _auto }